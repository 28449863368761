import React, {useContext, useEffect} from 'react';
import authStore, { TradingMode } from '../../../store/authStore';
import {
	createWatchlistMap,
	DEFAULT_FEED_ID,
	defaultWatchlistPreference,
	getDefaultJapanWatchlists,
	japanSpreadWatchlistName,
} from '../../functions/WatchlistUtils';
import { AccountMarketType } from '../../../gateways/RfpGateway/rfp.types';
import accountStatusStore, {StatusStore} from "../../../store/accountStatusStore";
import tradingAccountStore from "../../../store/tradingAccountStore";
import useSaveWatchlistToPreferences from "./useSaveWatchlistToPreferences";
import AppContext from "../../../contexts/AppContext";
import DashboardContext from "../../../contexts/DashboardContext";
// import usePromiseFactory from "../../utils/hooks/usePromiseFactory";
// import useForceRerender from "../../utils/hooks/useForceRerender";

// TODO: WIP - testing if the force rerender is really needed at this point
const useWatchlistSync = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);

	const isJapanAccount = authStore.use.isJapanAccount();
	const tradingMode = authStore.use.tradingMode();

	const setCurrentWatchList = tradingAccountStore.use.setCurrentWatchList();
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isJapanSpreadAccount = tradingAccountStore.use.isJapanSpread();
	const hasLiveAccount = accountStatusStore((state: StatusStore) => state.hasLiveAccount);
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();
	const currentWatchList = tradingAccountStore.use.currentWatchList();
	const marketItems = dashboardContext.marketItems;

	// const promiseFactory = usePromiseFactory();
	// const forceRerender = useForceRerender();

	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();

	useEffect(() => {
		console.info('>>> trigger a WL generation update dashboardContext.marketItems -> marketItems', marketItems);
	}, [marketItems]);


	useEffect(() => {
		console.info('>>> trigger a WL generation update appContext.userPreferenceError', appContext.userPreferenceError);
	}, [appContext.userPreferenceError]);

	useEffect(() => {
		console.info('>>> trigger a WL generation update appContext', appContext);
	}, [appContext]);

	useEffect(() => {
		console.info('>>> trigger a WL generation update dashboardContext.watchlist', dashboardContext.watchlist);
	}, [dashboardContext.watchlist]);

	useEffect(() => {
		console.info('>>> trigger a WL generation update dashboardContext.mappedWatchlist', dashboardContext.mappedWatchlist);
	}, [dashboardContext.mappedWatchlist]);

	useEffect(() => {
		console.info('>>> trigger a WL generation update');
		if (appContext.userPreferences !== null && marketItems.length > 0) {
			const { user_prefs: preferences } = appContext.userPreferences;

			const filteredByModeWatchlists = preferences.watchlists.account.filter((accountType) => {
				if (isSpreadBettingAccount) {
					return (
						accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === 'SpreadBetting'
					);
				} else if (isJapanSpreadAccount) {
					return (
						accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === 'JapanSpread'
					);
				} else {
					return accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === undefined;
				}
			});

			const selectedWatchlist = filteredByModeWatchlists.find(
				(item) => item._type.toUpperCase() === tradingMode.toUpperCase()
			);

			const hasNoWatchLists = selectedWatchlist?.watchlist.length === 0;
			const isJapanLiveWithNoLiveAccounts =
				isJapanAccount &&
				selectedWatchlist?._type.toUpperCase() === TradingMode.Live.toUpperCase() &&
				hasNoWatchLists &&
				hasLiveAccount !== undefined &&
				!hasLiveAccount;
			const isJapanDemo =
				isJapanAccount && selectedWatchlist?._type.toUpperCase() === TradingMode.Demo.toUpperCase() && hasNoWatchLists;

			if (
				appContext.userPreferenceError ||
				(!isJapanAccount && hasNoWatchLists) ||
				isJapanLiveWithNoLiveAccounts ||
				isJapanDemo ||
				(isJapanAccount && isJapanSubscriptionAccount && hasNoWatchLists) ||
				(isJapanAccount && isJapanSpreadAccount && hasNoWatchLists) ||
				(isSpreadBettingAccount && hasNoWatchLists)
			) {
				filteredByModeWatchlists.forEach((account) => {
					if (isJapanAccount) {
						if ((isJapanSubscriptionAccount && account.accountType === undefined) || isJapanDemo) {
							account.watchlist.push(...getDefaultJapanWatchlists(DEFAULT_FEED_ID, marketItems));
							setCurrentWatchList('Tier 1');
						} else if (
							(isJapanSpreadAccount && account.accountType === 'JapanSpread') ||
							isJapanLiveWithNoLiveAccounts
						) {
							account.watchlist.push(
								defaultWatchlistPreference(
									DEFAULT_FEED_ID,
									marketItems,
									japanSpreadWatchlistName,
									isSpreadBettingAccount,
									isJapanSpreadAccount || isJapanLiveWithNoLiveAccounts // this is a hack to fill the WL with JapanSpread instruments when no live account is found
								)
							);
							setCurrentWatchList(japanSpreadWatchlistName);
						}
					} else {

						// TODO: REFACTOR: For some reason this is irrelevant now, simplify in next iterations
						if (account.accountType && account.accountType === 'SpreadBetting') {
							account.watchlist.push(
								defaultWatchlistPreference(
									DEFAULT_FEED_ID,
									marketItems.filter((marketItem) => marketItem.accountMarketType === AccountMarketType.SpreadBetting),
									'My Watchlist',
									isSpreadBettingAccount,
									isJapanSpreadAccount
								)
							);
						} else {
							account.watchlist.push(
								defaultWatchlistPreference(
									DEFAULT_FEED_ID,
									marketItems,
									'My Watchlist',
									isSpreadBettingAccount,
									isJapanSpreadAccount
								)
							);
						}
						const watchlistName = account.watchlist[0]?._name ?? 'Popular Markets';
						setCurrentWatchList(watchlistName);
					}

					dashboardContext.watchlist = account.watchlist;
					dashboardContext.mappedWatchlist = createWatchlistMap(account.watchlist);

					saveWatchlistToPreferences(account.watchlist, !!account.accountType);
					appContext.userPreferenceError = false;
				});
			}
		}
	}, [
		marketItems,
		appContext.userPreferenceError,
		appContext,
		dashboardContext.watchlist,
		dashboardContext.mappedWatchlist,
		isSpreadBettingAccount,
		currentWatchList,
		isJapanAccount,
		isJapanSubscriptionAccount,
		isJapanSpreadAccount,
		hasLiveAccount,
	]);

	return null;
};

export default useWatchlistSync;
