import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
	ColumnFiltersState,
	ColumnResizeDirection,
	ColumnResizeMode,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	OnChangeFn,
	Row,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import InstrumentContext from '../../../../../../contexts/InstrumentContext';
import AppContext from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import useOrderTicketAccess from '../../../../../../utils/hooks/useOrderTicketAccess';
import { useMarketItemsMap } from '../../../../../components/MarketItemFormatter/useMarketItemsMap';
import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';

import { closeAllTickets, closedTableObj, positionTableObj } from '../helpers';
import { TradingPosition, TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useVirtualizer } from '@tanstack/react-virtual';
import RenderClearFilters from '../components/renderClearFilters';
import cn from 'classnames';
import styles from '../../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import SettingGrid from '../components/SettingsGrid';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import DraggableTableHeader from '../../../../Markets/MarketsGridNew/components/DraggableTableHeader';
import {
	CloseTableItem,
	PositionTableItem,
} from '../../../../../../utils/functions/marketItems/marketItemGroupMapFormatter';
import DragAlongCell from '../../../../Markets/MarketsGridNew/components/DragAlongCell';
import useClosedGridColumn from './useClosedGridColumn';
import useClosedTable from './useClosedTable';
import ClosedTicketModal from '../../../ChartPanel/NewOrderModals/ClosedTicketModal/ClosedTicketModal';

const ClosedTable = ({
	columnFilters,
	setColumnFilters,
}: {
	columnFilters: ColumnFiltersState;
	setColumnFilters: OnChangeFn<ColumnFiltersState>;
}) => {
	const instrumentContext = useContext(InstrumentContext);
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);

	const orderTicketAccess = useOrderTicketAccess();
	const marketsItemMap = useMarketItemsMap();
	const activeTradingAccount = useSelectedTradingAccount();

	const tradingPositions = dashboardContext.getTradingPositions();

	const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
	const [showClosedPositionTicket, setShowClosedPositionTicket] = useState<boolean>(false);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [columnResizeMode, _] = useState<ColumnResizeMode>('onChange');
	const [columnVisibility, setColumnVisibility] = useState({});
	const [globalFilter, setGlobalFilter] = React.useState('');
	const [highlightPosition, setHighlightPosition] = useState('');
	const [expanded, setExpanded] = React.useState({});
	const [positionData, setPositionData] = useState<any>([]);

	const tableContainerRef = React.useRef<HTMLDivElement>(null);
	const columns = useClosedGridColumn(setShowSettingsModal, setShowClosedPositionTicket);
	const tableData = useClosedTable();

	const [columnOrder, setColumnOrder] = React.useState<string[]>([]);

	useEffect(() => {
		const localDataOrder = localStorage.getItem('closedTableColumnsOrder');

		if (!columnOrder.length && localDataOrder?.length) {
			const parsedData = JSON.parse(localDataOrder);
			const quantityItem = parsedData.some((item: string) => item === 'Amount' || item === 'Lots');
			if (quantityItem || parsedData === [null]) {
				setColumnOrder(() => columns.map((c) => c.id!));
				localStorage.setItem('closedTableColumnsOrder', JSON.stringify(columns.map((c) => c.id!)));
			} else if (parsedData[parsedData.length - 1] === 'settings') {
				setColumnOrder(parsedData);
			}
		} else if (!columnOrder.length && !localDataOrder?.length) {
			setColumnOrder(() => columns.map((c) => c.id!));
		} else {
			localStorage.setItem('closedTableColumnsOrder', JSON.stringify(columnOrder));
		}
	}, [columnOrder]);

	const columnResizeDirection: ColumnResizeDirection = useMemo(() => {
		return appContext.isArabic ? 'rtl' : 'ltr';
	}, [appContext.isArabic]);

	// Initial width calculation

	const table = useReactTable({
		data: tableData,
		columns,
		columnResizeMode,
		columnResizeDirection,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		state: {
			sorting,
			columnVisibility,
			columnOrder,
			expanded,
			columnFilters,
			globalFilter,
		},
		onExpandedChange: setExpanded,
		getSubRows: (row) => row.subRows,
		onColumnVisibilityChange: setColumnVisibility,
		getFilteredRowModel: getFilteredRowModel(),
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onColumnOrderChange: setColumnOrder,
		getExpandedRowModel: getExpandedRowModel(),
		debugTable: true,
	});

	useEffect(() => {
		const localData = localStorage.getItem('closedTableColumns');

		const localSortingData = localStorage.getItem('closedSortingTable');

		if (!localData) {
			setColumnVisibility((state) => ({
				...state,
				...closedTableObj,
			}));
			localStorage.setItem('closedTableColumns', JSON.stringify(closedTableObj));
		} else {
			const data = JSON.parse(localData) as object;
			if (data && typeof data === 'object') {
				setColumnVisibility((state) => ({
					...state,
					...data,
				}));
			}
		}

		if (!localSortingData) {
			localStorage.setItem('closedSortingTable', JSON.stringify(sorting));
		} else {
			const data = JSON.parse(localSortingData);
			if (data) {
				setSorting(data);
			}
		}

		const setItemToStorage = () => {
			localStorage.setItem('closedTableColumns', JSON.stringify(table.getState().columnVisibility));
			localStorage.setItem('positionSortingTable', JSON.stringify(table.getState().sorting));
		};

		window.addEventListener('beforeunload', setItemToStorage);

		return () => {
			setItemToStorage();
			window.addEventListener('beforeunload', setItemToStorage);
		};
	}, []);

	const handleSelectedInstrument = (cell: any) => {
		if (cell.column.id === 'TakeProfit' || cell.column.id === 'StopLoss') {
			return;
		}

		if (!orderTicketAccess()) {
			return;
		}

		const data = cell.row.original;

		const record = marketsItemMap[cell.row.original.code];

		if (!record) {
			return;
		}

		const marketItem = instrumentContext.instruments.find(
			(instrument) => instrument.feedId === record.feedId && instrument.code === record.code
		);

		if (!marketItem) {
			return;
		}

		if (record) {
			const closedPositions = tradingPositions.filter(
				(position: TradingPosition) =>
					position.code === record.code &&
					position.state === TradingPositionState.closed &&
					position.posId === data.posId
			);
			setPositionData(closedPositions);
			if (marketItem) {
				dashboardContext.closeAllOtherTabs();
				dashboardContext.isEdit = false;
				dashboardContext.selectedInstrument = marketItem;
				dashboardContext.symbolChanged = marketItem.code;
				dashboardContext.gridChartsChanged = !dashboardContext.gridChartsChanged;
				setHighlightPosition(data.posId);
			}
		}

		closeAllTickets(dashboardContext);
	};

	// reorder columns after drag & drop
	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event;
		if (active && over && active.id !== over.id) {
			setColumnOrder((columnOrder) => {
				const oldIndex = columnOrder.indexOf(active.id as string);
				const newIndex = columnOrder.indexOf(over.id as string);
				return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
			});
		}
	}

	const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

	const { rows } = table.getRowModel();

	//dynamic row height virtualization - alternatively you could use a simpler fixed row height strategy without the need for `measureElement`
	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: useCallback(() => 40, []), //estimate row height for accurate scrollbar dragging
		getScrollElement: () => tableContainerRef.current,
		//measure dynamic row height, except in firefox because it measures table border height incorrectly
		measureElement:
			typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
				? (element) => element?.getBoundingClientRect().height
				: undefined,
		overscan: 3,
	});

	const virtualRows = rowVirtualizer.getVirtualItems();

	if (!tableData.length) {
		return <RenderClearFilters columnFilters={columnFilters} setColumnFilters={setColumnFilters} />;
	}

	return (
		<div className={cn(styles.positionPageTableWrapper)}>
			{showSettingsModal && (
				<SettingGrid columns={table.getAllLeafColumns()} setShowSettingsModal={setShowSettingsModal} />
			)}

			{showClosedPositionTicket && (
				<div>
					<ClosedTicketModal
						data={positionData}
						showClosedPositionTicket={showClosedPositionTicket}
						setShowClosedPositionTicket={setShowClosedPositionTicket}
					/>
				</div>
			)}

			<DndContext
				collisionDetection={closestCenter}
				modifiers={[restrictToHorizontalAxis]}
				onDragEnd={handleDragEnd}
				sensors={sensors}
			>
				<div style={{ direction: table.options.columnResizeDirection }}>
					<div ref={tableContainerRef} className={cn(styles.tableContainerVirtualStyles)}>
						<table
							{...{
								className: cn(styles.table),
							}}
						>
							<thead className={cn(styles.stickyHeader)}>
								{table.getHeaderGroups().map((headerGroup) => {
									return (
										<tr key={headerGroup.id}>
											<SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
												{headerGroup.headers.map((header) => (
													<DraggableTableHeader key={header.id} header={header} table={table} />
												))}
											</SortableContext>
										</tr>
									);
								})}
							</thead>

							<tbody
								style={{
									height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
									position: 'relative', //needed for absolute positioning of rows
								}}
							>
								{virtualRows.map((virtualRow) => {
									const row = rows[virtualRow.index] as Row<CloseTableItem>;

									const position = row.original.posId;

									let positionClass = position === highlightPosition;
									return (
										<tr
											key={row.id}
											data-index={virtualRow.index} //needed for dynamic row height measurement
											// ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
											className={cn(styles.tableRow, positionClass && styles.tableRowTransparent)}
											style={{
												position: 'absolute',
												transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
												width: '100%',
												height: `${virtualRow.size}px`, //this should always be a `style` as it changes on scroll
												display: 'flex',
											}}
										>
											{row.getVisibleCells().map((cell) => {
												if (cell.column.id === 'instrument' || cell.column.id === 'settings') {
													return (
														<td
															{...{
																key: cell.id,
																className: cn({
																	[styles.tableData]: true,
																	[styles.td]: true,
																}),
																style: {
																	opacity: 1,
																	position: 'relative',
																	width: cell.column.getSize(),
																	zIndex: 0,
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	lineHeight: '1.5',
																},
																onClick: () => handleSelectedInstrument(cell),
															}}
														>
															{flexRender(cell.column.columnDef.cell, cell.getContext())}
														</td>
													);
												}

												return (
													<SortableContext
														key={cell.id}
														items={columnOrder}
														strategy={horizontalListSortingStrategy}
														disabled={cell.column.id === 'instrument' || cell.column.id === 'settings'}
													>
														<DragAlongCell
															key={cell.id}
															cell={cell}
															handleSelectedInstrument={handleSelectedInstrument}
														/>
													</SortableContext>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</DndContext>
		</div>
	);
};

export default ClosedTable;
