import React, { useState, useEffect, useContext, useMemo, useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import moment from 'moment';

import Button from '../../../../../../shared/Button/Button';

import { RFP } from '../../../../../../gateways/RfpGateway/rfpConstants';

import {
	NewOrderRequest,
	TradeRequest,
	NewPositionRequest,
	EditOrderRequest,
	PriceQuote,
	TradingAccount,
} from '../../../../../../gateways/RfpGateway/rfp.types';
import InstrumentHeader from '../../../../../components/InstrumentSummary/InstrumentHeader';

import { default as RfpGatewayContext } from '../../../../../../contexts/RfpGatewayContext';
import { default as DashboardContext } from '../../../../../../contexts/DashboardContext';
import { default as AppContext } from '../../../../../../contexts/AppContext';
import { default as usePromiseFactory } from '../../../../../../utils/hooks/usePromiseFactory';
import { default as useForceRerender } from '../../../../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../../../../utils/hooks/useObservable';
import { TradingPosition, TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import { Optional } from '../../../../../../utils/functions/Nullable';
import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';
import {
	convertLotToAmount,
	getEffectiveSize,
	getHedgedTradeQuantity,
	getMarginImpact,
	convertAmountToLot,
	convertToBaseCurrency,
	getAdditionalSubscriptionPairs,
	getMarginCalculationType,
} from '../../../../../../utils/functions/calculations';

import Modal from '../../../../../../shared/Modal/Modal';
import { formatNumberWithCommas } from '../../../Watchlist/Instrument/formattedQuoteNumber';
import {
	MarginAccountType,
	QUANTITY_TYPE,
	TicketLayout,
	TradingPositionSide,
	TradingPositionType,
} from '../../../../../../utils/functions/enums';

import orderStore from '../../../../../../store/OrderStore/orderStore';

import { TradersGymContext, TradersGymContextType } from '../../../../../../pages/TradersGym/TradersGymContext';
import { getFormattedDateHelper, getGeneralFormatDate } from '../../../../../../utils/functions/subscriptionUtils';

import ConditionalWrapper from '../../../../../../shared/ConditionalWrapper';
import DraggableModalDialog from '../../../../../../shared/Modal/DraggableModalDialog';

import { GymPositionUtils } from '../../../../../../pages/TradersGym/Positions/GymPositionUtils';
import { GymTradingManager } from '../../../../../../pages/TradersGym/Accounts/GymTradingManager';
import { GymTradingPosition } from '../../../../../../pages/TradersGym/Positions/GymTradingPosition';
import { addToastNotification } from '../../../../../../utils/functions/toastNotificationUtils';

import { storeInstrumentsPipsValue } from '../../../TradeTicket/LimitProfitLoss/utils';

import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';

import { ActionType } from '../../../../../../store/OrderStore/orderStore.types';
import tradingAccountStore from '../../../../../../store/tradingAccountStore';
import useUpdateUserPrefPips from '../../../../../../utils/mutations/useUpdateUserPrefPips';

import MarginRequirementsContext from '../../../../../../contexts/MarginRequirementsContext';

import pipStore from '../../../../../../store/PipsStore/pipStore';
import tradingViewStore from '../../../../../../store/tradingViewStore';

import styles from './ConfirmOrderModal.module.scss';

interface ConfirmOrderModalProps {
	tradeRequest: TradeRequest;
}

const ConfirmOrderModal = ({ tradeRequest }: ConfirmOrderModalProps) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const tradingPosition = orderStore((state) => state.tradingPosition);
	const current = orderStore((state) => state.current);
	const action = orderStore((state) => state.action);
	const resetOrderStore = orderStore.use.reset();
	const setOpenPriceInfo = orderStore.use.setOpenPriceInfo();
	const setAction = orderStore.use.setAction();
	const createTradeRequestObject = orderStore.use.createTradeRequestObject();
	const isSignalOrder = orderStore.use.isSignalOrder();
	const setCurrentQuote = orderStore.use.setCurrentQuote();
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;
	const marginRequirementsContext = useContext(MarginRequirementsContext);

	const realTradingAccount = useSelectedTradingAccount();
	const gymTradingAccount = tradersGymContext.gymTradingAccount;

	const [selectedTradingAccount, setSelectedTradingAccount] = useState<TradingAccount>(realTradingAccount);

	const rfpGatewayContext = useContext(RfpGatewayContext);
	const forceRerender = useForceRerender();
	const promiseFactory = usePromiseFactory();
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');
	const updateUserPrefPips = useUpdateUserPrefPips();

	const languageSettings = appContext.languageSettings;

	const selectedMarketItem = dashboardContext.selectedInstrument;
	const activeTradingAccount = useSelectedTradingAccount();
	const tradingAccount = dashboardContext.tradingAccount;
	const tradingPositions = dashboardContext.getTradingPositions();

	const orderSide = current.side;
	const quantityType = dashboardContext.quantityType;
	const instrumentLastTrade = dashboardContext.instrumentLastTrade;
	const ticketLayout = tradingViewStore.use.ticketLayout();
	const modifyTicket = dashboardContext.modifyTicket;
	const limitProfitLossValues = appContext.limitProfitLossValues;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const takeProfitPips = pipStore.use.takeProfit();
	const stopLossPips = pipStore.use.stopLoss();
	const marketItems = dashboardContext.marketItems;
	const subIdRef = useRef<string | undefined>(undefined);

	useEffect(() => {
		if (tradersGymContext.isActive && gymTradingAccount) {
			setSelectedTradingAccount(gymTradingAccount);
		} else {
			setSelectedTradingAccount(realTradingAccount);
		}
	}, [realTradingAccount, gymTradingAccount]);

	useObservable(appContext.getPropertyChangeStream('email'), async (change) => {
		await promiseFactory.throttle('appContext.propertyChanged', 100);
		forceRerender();
	});

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'newOrderModalToggle',
			'selectedInstrument',
			'orderModalOpenedFrom',
			'tradingAccount',
			'quantityType',
			'instrumentLastTrade',
			'modifyTicket',
			'marketItems',
			'tradingPositions',
			'accountValues'
		),
		async (_change) => {
			await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
			forceRerender();
		}
	);

	const [convertDate, setConvertDate] = useState('');
	const [rndLot, setRndLot] = useState<number>(0);
	const [currentPriceQuote, setCurrentPriceQuote] = useState<PriceQuote>();
	const [marginImpact, setMarginImpact] = useState(0);

	useEffect(() => {
		if (selectedMarketItem) {
			const instrument = selectedTradingAccount?.tradingInstruments?.instruments[selectedMarketItem.code];
			setRndLot(instrument?.rndLot || 0);
		}
	}, [selectedMarketItem, selectedTradingAccount]);

	const handleCancelButtonClick = () => {
		dashboardContext.showConfirmTicket = false;
		dashboardContext.showOrderTicket = false;
		dashboardContext.isEdit = false;
		dashboardContext.activeIndex = 0;
		dashboardContext.newOrderModalToggle = {
			orderTicket: false,
			confirmOrder: false,
		};
		resetOrderStore();
	};

	useEffect(() => {
		switch (action) {
			case ActionType.cancel:
				handleCancelButtonClick();

				// The Action must be reset after use it
				setAction(ActionType.unknown);
				break;
		}
	}, [action]);

	useEffect(() => {
		setAction(ActionType.confirm);

		const dateFormat = 'D/MMM/YYYY';
		if (tradeRequest && tradeRequest.tradeType) {
			let newConvertDate = '';
			switch (tradeRequest.expiration) {
				case 'EXP_DAY' || 'Day':
					const currentDatetime = moment().unix() * 1000;
					newConvertDate = appContext.isJapanAccount
						? getGeneralFormatDate(currentDatetime, false, true)
						: getFormattedDateHelper(currentDatetime, dateFormat).toString().toUpperCase();
					break;
				case 'EXP_WEEK':
					const expWeekDatetime = moment().add(1, 'week').unix() * 1000;
					newConvertDate = appContext.isJapanAccount
						? getGeneralFormatDate(expWeekDatetime, false, true)
						: getFormattedDateHelper(expWeekDatetime, dateFormat).toString().toUpperCase();
					break;
				case 'EXP_MONTH':
					const expMonthDatetime = moment().add(1, 'month').unix() * 1000;
					newConvertDate = appContext.isJapanAccount
						? getGeneralFormatDate(expMonthDatetime, false, true)
						: getFormattedDateHelper(expMonthDatetime, dateFormat).toString().toUpperCase();
					break;
				case 'EXP_GTC':
					newConvertDate = t('wtr:GOOD_TILL_CANCEL');
					break;
				default:
					const baseDatetime = moment(new Date(tradeRequest.expiration ?? 0)).unix() * 1000;
					newConvertDate = appContext.isJapanAccount
						? getGeneralFormatDate(baseDatetime, false, true)
						: getFormattedDateHelper(baseDatetime, dateFormat).toString().toUpperCase();
			}
			setConvertDate(newConvertDate);
		}
	}, []);

	const addAdditionalSubPairsCodes = (subCodes: string[]): void => {
		if (selectedTradingAccount && selectedMarketItem) {
			let pairs = getAdditionalSubscriptionPairs(selectedTradingAccount, selectedMarketItem);
			if (pairs) {
				pairs.forEach((pair) => {
					subCodes.push(pair);
				});
			}
		}
	};

	useEffect(() => {
		if (tradersGymContext.priceQuote) {
			setCurrentPriceQuote(tradersGymContext.priceQuote);
		}
	}, [tradersGymContext.priceQuote]);

	useEffect(() => {
		if (rfpGatewayContext && selectedMarketItem) {
			// unsubscribe previous quotes
			if (subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
			}
			const feedId = selectedMarketItem.feedId;
			const code = selectedMarketItem.code;
			let subCodes: string[] = [code];

			addAdditionalSubPairsCodes(subCodes);

			// subscribe for price quote
			const subId = rfpGatewayContext.subscribePriceQuote(feedId, subCodes, (priceQuote) => {
				if (!tradersGymContext.isActive) {
					if (priceQuote.c === selectedMarketItem.code) {
						setCurrentPriceQuote(priceQuote);
					}
				}
			});

			subIdRef.current = subId;
		}

		// unsubscribe price quote on unmount
		return () => {
			if (rfpGatewayContext && subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
				subIdRef.current = undefined;
			}
		};
	}, [selectedMarketItem, rfpGatewayContext]);

	const showInvalidNotification = () => {
		addToastNotification('danger', t('wtr:INVALID_DATA'), t('en:ORDER_FAILED'));
	};

	const handleModalSuccess = async () => {
		if (rfpGatewayContext) {
			dashboardContext.orderConfirm.edit = false;
			dashboardContext.orderConfirm.close = false;
			dashboardContext.orderConfirm.cancel = false;
			dashboardContext.showOrderTicket = false;

			const tradeRequestObject = createTradeRequestObject({ takeProfitPips, stopLossPips }, currentPriceQuote);

			if (tradeRequestObject && tradeRequestObject.posId === undefined) {
				const isBuy = tradeRequestObject.tradeSide === TradingPositionSide.Buy;
				const checkAlpha = /[a-zA-Z]/g;
				if (
					tradeRequestObject.tradeType === TradingPositionType.Limit ||
					tradeRequestObject.tradeType === TradingPositionType.Stop
				) {
					if (
						tradeRequestObject.priceLevel &&
						(checkAlpha.test(tradeRequestObject.priceLevel.toString()) ||
							checkAlpha.test(tradeRequestObject.tradeSize!.toString()) ||
							isNaN(tradeRequestObject.lotAmountQty!))
					) {
						showInvalidNotification();
					} else {
						// New Order
						if (tradersGymContext.isActive && tradersGymContext.lastHistoryTick && tradersGymContext.simulation) {
							const account = tradersGymContext.gymTradingAccount;
							if (account && selectedMarketItem && tradersGymContext.simulation) {
								const position = GymPositionUtils.buildPendingPosition(
									account,
									selectedMarketItem,
									tradeRequestObject.priceLevel,
									isBuy,
									tradersGymContext.lastHistoryTick,
									tradeRequestObject.tradeType,
									tradersGymContext.simulation.id,
									tradeRequestObject.stopLevel,
									tradeRequestObject.limitLevel,
									tradeRequestObject.tradeSize
								);

								const priceQuote = gymContext.convertHistoryTickToPriceQuote(tradersGymContext.lastHistoryTick);
								GymTradingManager.sharedInstance().openOrder(account, position, tradersGymContext.simulation);
								account.updatePositions(tradersGymContext.lastHistoryTick, priceQuote, tradersGymContext.simulation.id);
							}
						} else {
							rfpGatewayContext.send(RFP.newOrder, tradeRequestObject as NewOrderRequest);
						}
					}
				} else {
					if (
						tradeRequestObject &&
						(!tradeRequestObject.tradeSize ||
							checkAlpha.test(tradeRequestObject.tradeSize.toString()) ||
							isNaN(tradeRequestObject.lotAmountQty!))
					) {
						showInvalidNotification();
					} else {
						dashboardContext.orderConfirm.edit = true;

						// New trade
						if (tradersGymContext.isActive && tradersGymContext.lastHistoryTick && tradersGymContext.simulation) {
							const account = tradersGymContext.gymTradingAccount;
							if (account && selectedMarketItem && tradersGymContext.simulation && currentPriceQuote) {
								const position = GymPositionUtils.buildPosition(
									account,
									selectedMarketItem,
									isBuy,
									currentPriceQuote,
									tradersGymContext.simulation.id,
									tradeRequestObject.stopLevel,
									tradeRequestObject.limitLevel,
									tradeRequestObject.tradeSize
								);

								const priceQuote = gymContext.convertHistoryTickToPriceQuote(tradersGymContext.lastHistoryTick);

								GymTradingManager.sharedInstance().openOrder(account, position, tradersGymContext.simulation);
								account.updatePositions(tradersGymContext.lastHistoryTick, priceQuote, tradersGymContext.simulation.id);
							}
						} else {
							rfpGatewayContext.send(RFP.newTrade, tradeRequestObject as NewPositionRequest);
						}
					}
				}
				dashboardContext.orderConfirm.new = true;
			}

			if (tradeRequest && tradeRequest.posId !== undefined) {
				if (tradersGymContext.isActive && tradersGymContext.gymTradingAccount && tradingPosition) {
					const currentPos = tradingPosition as GymTradingPosition;
					const pos = currentPos.deepCopy();
					if (pos) {
						if (pos.state === TradingPositionState.pending && tradeRequest.priceLevel) {
							pos.prc = tradeRequest.priceLevel;
						}
						pos.tp = tradeRequest.limitLevel ?? 0;
						pos.sl = tradeRequest.stopLevel ?? 0;

						GymTradingManager.sharedInstance().modifyPosition(tradersGymContext.gymTradingAccount, pos);
					}
				} else {
					rfpGatewayContext.send(RFP.editOrder, tradeRequestObject as EditOrderRequest);
				}
				dashboardContext.orderConfirm.edit = true;
			}

			dashboardContext.orderValue.takeProfit = '';
			dashboardContext.newOrderModalToggle = {
				orderTicket: false,
				confirmOrder: false,
			};
			dashboardContext.activeIndex = 0;
			dashboardContext.isEdit = false;
			dashboardContext.getOrderInfo = tradeRequest;
			dashboardContext.tradeInformation = undefined;
			if (ticketLayout === TicketLayout.Dock) {
				dashboardContext.showConfirmTicket = false;
			}
			let instrumentTrade: any = {};
			let lastTrade: any = JSON.parse(JSON.stringify(instrumentLastTrade));

			if (tradeRequest && tradeRequest.code) {
				instrumentTrade[tradeRequest.code] = {
					quantity: tradeRequest.tradeSize,
					quantityType: quantityType,
					typeOfOrder: tradeRequest.tradeType ? tradeRequest.tradeType : 'market',
					tp: tradeRequest.limitLevel,
					sl: tradeRequest.stopLevel,
				};
			}

			if (lastTrade.length === 0) {
				lastTrade.push(instrumentTrade);
			}

			let i = -1;
			lastTrade.forEach((element: any, index: any) => {
				if (element.hasOwnProperty(tradeRequest.code)) {
					i = index;
				}
			});

			if (i > -1) {
				lastTrade[i] = instrumentTrade;
			} else {
				lastTrade.push(instrumentTrade);
			}
			dashboardContext.instrumentLastTrade = lastTrade;
			setAction(ActionType.unknown);
			storeInstrumentsPipsValue(limitProfitLossValues);
			resetOrderStore();
		}

		// Don't save default pips if the order is created from a Signal
		if (!isSignalOrder) {
			updateUserPrefPips();
		}
	};

	const editOrderTicket = () => {
		if (ticketLayout === TicketLayout.Undock) {
			dashboardContext.newOrderModalToggle = {
				orderTicket: true,
				confirmOrder: false,
			};
			dashboardContext.showOrderTicket = false;
			dashboardContext.isEdit = false;
		} else {
			dashboardContext.showConfirmTicket = false;

			dashboardContext.showOrderTicket = true;
			dashboardContext.isEdit = true;
		}
		setAction(ActionType.unknown);
	};

	const currentOrderType = tradeRequest?.tradeType ? tradeRequest.tradeType.toLowerCase() : 'market';

	const formatText = (text: string | any) => {
		if (text) {
			return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
		}
	};

	const calcMarketOrderVal = useMemo(() => {
		if (tradeRequest!.tradeSize) {
			const currentPrice = (tradeRequest.tradeSide === 'BUY' ? currentPriceQuote?.a : currentPriceQuote?.b) ?? 0;
			const orderVal = tradeRequest!.tradeSize * currentPrice;
			return isNaN(orderVal) ? tt('N/A') : formatNumberWithCommas(orderVal, 2, languageSettings);
		}

		return tt('N/A');
	}, [currentPriceQuote?.a, currentPriceQuote?.b, tradeRequest]);

	const calcNonMarketOrderVal = useMemo(() => {
		if (!modifyTicket) {
			let orderVal: number = 0;
			if (tradeRequest && tradeRequest.priceLevel && tradeRequest.tradeSize) {
				orderVal = tradeRequest.tradeSize * tradeRequest.priceLevel;
				return isNaN(orderVal) ? tt('N/A') : formatNumberWithCommas(orderVal, 2, languageSettings);
			} else {
				return tt('N/A');
			}
		} else {
			if (tradeRequest && tradeRequest.tradeSize) {
				let orderVal: number = 0;
				if (tradeRequest.priceLevel && tradeRequest.tradeSize) {
					orderVal = tradeRequest.tradeSize * tradeRequest.priceLevel;
					return isNaN(orderVal) ? tt('N/A') : formatNumberWithCommas(orderVal, 2, languageSettings);
				} else {
					return tt('N/A');
				}
			}
		}
	}, [modifyTicket, tradeRequest]);

	const calculateOrderValue = useMemo(
		() => (currentOrderType === 'market' ? calcMarketOrderVal : calcNonMarketOrderVal),
		[currentOrderType, calcMarketOrderVal, calcNonMarketOrderVal]
	);

	const calculateQuantity = () => {
		if (!modifyTicket) {
			const size: number = tradeRequest.tradeSize!;
			if (isNaN(size)) {
				return tt('N/A');
			} else {
				const quantity = (quantityType === QUANTITY_TYPE.LOTS ? convertAmountToLot(size, rndLot) : size).toString();
				return (
					tradeRequest &&
					formatNumberWithCommas(
						quantity,
						quantity?.includes('.') ? quantity?.split('.')[1].length : 0,
						languageSettings
					)
				);
			}
		} else {
			let size = tradeRequest.tradeSize!;
			size = quantityType === QUANTITY_TYPE.LOTS ? convertAmountToLot(size, rndLot) : size;
			return isNaN(size) ? tt('N/A') : size?.toLocaleString();
		}
	};

	const getPrice = useCallback(() => {
		if (currentOrderType === 'market') {
			if (!currentPriceQuote) {
				return null;
			}

			return currentPriceQuote!.a!;
		}

		if (currentOrderType === 'limit' || currentOrderType === 'stop') {
			return tradeRequest.priceLevel;
		}

		return null;
	}, [currentOrderType, currentPriceQuote, tradeRequest.priceLevel]);

	const calculateMarginImpact = useCallback(() => {
		const account = selectedTradingAccount;
		if (account && selectedMarketItem) {
			let impact: number = 0;

			const instrument = account.tradingInstruments?.instruments[selectedMarketItem.code];
			const marginRequirement = instrument && instrument.marginReq ? instrument.marginReq : 0;
			const rndLot = instrument && instrument.rndLot ? instrument.rndLot : 0;

			const price = getPrice();
			const amount = tradeRequest.tradeSize!;
			const qty = quantityType === 'Amount' ? amount : convertAmountToLot(amount, rndLot)!;

			if (price || price === 0) {
				const marginAccountType = getMarginCalculationType(account);
				if (marginAccountType !== MarginAccountType.Unhedged) {
					const openPositions: TradingPosition[] = tradingPositions.filter(
						(position: TradingPosition) => position.state === TradingPositionState.open
					);

					const effectiveSize = getEffectiveSize(marginAccountType, selectedMarketItem.code, account.id, openPositions);
					const hedgedTradeQuantity = getHedgedTradeQuantity(effectiveSize, qty, orderSide).value;
					impact = getMarginImpact(
						marginRequirement,
						quantityType === 'Amount' ? +hedgedTradeQuantity : convertLotToAmount(+hedgedTradeQuantity, rndLot),
						price
					);
				} else {
					impact = getMarginImpact(marginRequirement, amount, price);
				}

				const convertedToBaseCurrencyImpact = convertToBaseCurrency(
					account,
					impact,
					price,
					selectedMarketItem.qCcy,
					selectedMarketItem.feedId,
					selectedMarketItem.code,
					orderSide === TradingPositionSide.Buy
				);

				setMarginImpact(convertedToBaseCurrencyImpact);
			}
		}
	}, [
		activeTradingAccount,
		getPrice,
		marketItems,
		orderSide,
		quantityType,
		selectedMarketItem,
		tradeRequest.lotAmountQty,
		tradingAccount,
		tradingPositions,
	]);

	useEffect(() => {
		if (currentPriceQuote) {
			setCurrentQuote(currentPriceQuote);
			if (orderSide === TradingPositionSide.Buy || orderSide === TradingPositionSide.Sell) {
				let typeOfOrder = tradeRequest.tradeType ? tradeRequest.tradeType : TradingPositionType.Market;
				if (typeOfOrder === TradingPositionType.Market) {
					const isBuy = orderSide === TradingPositionSide.Buy;
					const orderPrice = isBuy ? currentPriceQuote.a : currentPriceQuote.b;
					setOpenPriceInfo({ price: orderPrice });
				}
			}
		}
	}, [currentPriceQuote]);

	useEffect(() => {
		calculateMarginImpact();
	}, [calculateMarginImpact]);

	const getAccountType = () => {
		switch (selectedTradingAccount?.accountType) {
			case 'DEMO':
				return t('wtr:DEMO');
			case 'LIVE':
				return t('wtr:LIVE');
			case 'GYM':
				return t('wtr:GYM');
		}
	};
	return (
		<ConditionalWrapper
			condition={ticketLayout === TicketLayout.Undock}
			wrapper={(children: any) => (
				<Modal show animation centered dialogClassName={styles.modalDialog} dialogAs={DraggableModalDialog}>
					<Modal.Body className={styles.modalBody}>{children} </Modal.Body>
				</Modal>
			)}
		>
			<div className={styles.container}>
				<InstrumentHeader resizeHeader />
				<div className={styles.fieldContainer}>
					<div className={styles.confirmField}>
						<div>{tt('ACCOUNT')}</div>
						<div>
							{getAccountType()} {!tradersGymContext.isActive ? selectedTradingAccount?.providerAccountId : ''} (
							{selectedTradingAccount?.baseCurrency})
						</div>
					</div>
					<div className={styles.confirmField}>
						<div>{tt('SIDE')}</div>
						<div>
							{tradeRequest &&
								(tradeRequest.tradeSide === 'BUY'
									? formatText(tt('BUY'))
									: tradeRequest.tradeSide === 'SELL'
									? formatText(tt('SELL'))
									: formatText(tt('NEW')))}
						</div>
					</div>
					<div className={styles.confirmField}>
						<div>{tt('ORDER_TYPE')}</div>
						<div>
							{tradeRequest && tradeRequest.tradeType
								? tradeRequest.tradeType === 'STOP'
									? formatText(tt('TYPE_STOP'))
									: formatText(tt('TYPE_LIMIT'))
								: formatText(tt('PRICE_MARKET'))}
						</div>
					</div>
					{tradeRequest && (tradeRequest.tradeType === 'LIMIT' || tradeRequest.tradeType === 'STOP') && (
						<div className={styles.confirmField}>
							<div>{tt('OPEN_PRICE')}</div>
							<div>
								{tradeRequest &&
									(tradeRequest.priceLevel
										? formatNumberWithCommas(
												tradeRequest.priceLevel ?? 0,
												selectedMarketItem?.decPrec ?? 0,
												languageSettings
										  )
										: '')}
							</div>
						</div>
					)}
					{tradeRequest && tradeRequest.tradeType && (
						<div className={styles.confirmField}>
							<div>{tt('EXPIRY_ON')}</div>
							<div className={styles.convertDate}>{convertDate}</div>
							{/* <div>{tradeRequest && `${expiryDate.getDate()}/${months[expiryDate.getMonth()].toUpperCase()}/${expiryDate.getFullYear()}`}</div> */}
						</div>
					)}
					<div className={styles.confirmField}>
						<div>{tt('PRICE')}</div>
						<div>
							{formatNumberWithCommas(
								tradeRequest.tradeSide === 'BUY' ? currentPriceQuote?.a ?? 0 : currentPriceQuote?.b ?? 0,
								selectedMarketItem?.decPrec ?? 0,
								languageSettings
							)}
						</div>
					</div>
					<div className={styles.confirmField}>
						<div>{tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}</div>
						<div>{calculateQuantity()}</div>
					</div>
					{tradeRequest && (
						<div className={styles.confirmField}>
							<div>{t('wtr:WTR_EQ_ORDER_VALUE')}</div>
							<div>{calculateOrderValue}</div>
						</div>
					)}
					{!marginRequirementsContext?.doesRulesApply &&
						!marginRequirementsContext?.marginRequirementsToApply &&
						!modifyTicket && (
							<div className={styles.confirmField}>
								<div>{t('wtr:MARGIN_IMPACT')}</div>
								<div>{formatNumberWithCommas(!isNaN(marginImpact) ? marginImpact : 0, 2, languageSettings)}</div>
							</div>
						)}
				</div>

				<div className={styles.footer}>
					<Button
						variant="secondary"
						size="lg"
						label={tt('EDIT')}
						className={styles.ticketButton}
						onClick={editOrderTicket}
					/>
					<Button
						label={tt('PLACE_ORDER')}
						variant="primary"
						size="lg"
						className={styles.ticketButtonClose}
						onClick={handleModalSuccess}
					/>
				</div>
			</div>
		</ConditionalWrapper>
	);
};

export default ConfirmOrderModal;
