import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';

import { TradersGymContext, TradersGymContextType } from '../pages/TradersGym/TradersGymContext';
import useSelectedTradingAccount from '../utils/hooks/useSelectedTradingAccount';
import { AccountMarketType, TradingAccount, TTMarginRuleItem } from '../gateways/RfpGateway/rfp.types';

import usePromiseFactory from '../utils/hooks/usePromiseFactory';
import useForceRerender from '../utils/hooks/useForceRerender';
import useObservable from '../utils/hooks/useObservable';

import { RFP } from '../gateways/RfpGateway/rfpConstants';
import { Resolver } from '../utils/functions/Ioc';
import RfpGateway from '../gateways/RfpGateway/RfpGateway';

import UniqueId from '../utils/functions/uniqueId';
import orderStore from '../store/OrderStore/orderStore';

import MarginRulesContext from './MarginRulesContext';
import DashboardContext from './DashboardContext';

interface MarginRequirementsContextProps {
	selectedAccountNumber?: number;
	doesRulesApply: boolean;
	marginRequirementsToApply?: TTMarginRuleItem;
}

const ACCOUNT_MARKET_TYPES_NOT_ALLOWED = [AccountMarketType.Japan, AccountMarketType.JapanSpread];
const MarginRequirementsContext = createContext<MarginRequirementsContextProps | undefined>(undefined);

export const MarginRequirementsContextProvider: FC<{
	children: ReactNode;
}> = ({ children }) => {
	const rfpGateway = Resolver.resolve(RfpGateway);

	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const dashboardContext = useContext(DashboardContext);
	const marginRulesContext = useContext(MarginRulesContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();
	const { tradersGymContext } = gymContext;
	const realTradingAccount = useSelectedTradingAccount();
	const gymTradingAccount = tradersGymContext.gymTradingAccount;
	const [selectedTradingAccount, setSelectedTradingAccount] = useState<TradingAccount>(realTradingAccount);
	const [doesRulesApply, setDoesRulesApply] = useState<boolean>(false);
	const [marginRequirementsToApply, setMarginRequirementsToApply] = useState<TTMarginRuleItem>();
	const { accountId, marginRules, futureMarginReqId, futureMarginValue } = marginRulesContext;
	const current = orderStore((state) => state.current);

	const detailedInfo = dashboardContext.detailedInformation;
	const selectedMarketItem = dashboardContext.selectedInstrument;

	// TODO: Refactor the following all over the application into the useSelectedTradingAccount hook
	useEffect(() => {
		if (tradersGymContext.isActive && gymTradingAccount) {
			setSelectedTradingAccount(gymTradingAccount);
		} else {
			setSelectedTradingAccount(realTradingAccount);
		}
	}, [realTradingAccount, gymTradingAccount, tradersGymContext.isActive]);

	useObservable(
		dashboardContext.getPropertyChangeStream('selectedInstrument', 'detailedInformation'),
		async (_change) => {
			await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
			forceRerender();
		}
	);

	// Had to implement it this way because the current architecture doesn't allow to use proper simple React Contexts,
	// or connect to Zustand store in the websocket subscriptions handler file
	useObservable(
		marginRulesContext.getPropertyChangeStream('accountId', 'marginRules', 'futureMarginReqId', 'futureMarginValue'),
		async () => {
			await promiseFactory.throttle('marginRulesContext.propertyChanged', 100);
			forceRerender();
		}
	);

	useEffect(() => {
		return () => {
			marginRulesContext.resetFutureMargin();
		};
	}, []);

	useEffect(() => {
		if (
			selectedTradingAccount?.accountType === 'GYM' ||
			(selectedTradingAccount &&
				selectedTradingAccount.accountMarketType &&
				ACCOUNT_MARKET_TYPES_NOT_ALLOWED.includes(selectedTradingAccount.accountMarketType))
		) {
			setDoesRulesApply(false);
			marginRulesContext.reset();
			return;
		}

		if (selectedTradingAccount?.accountNumber) {
			console.info('rfpGatewayConnection.send(RFP.requestTTMarginRules', {
				accountId: selectedTradingAccount.id,
				accountNumber: selectedTradingAccount.accountNumber,
			});

			rfpGateway.send(RFP.requestTTMarginRules, {
				accountId: selectedTradingAccount.accountNumber,
			});
		}
	}, [selectedTradingAccount]);

	useEffect(() => {
		if (selectedTradingAccount?.id && selectedTradingAccount?.accountType !== 'GYM') {
			const selectedAccountDetailedInformation = detailedInfo.find(
				({ account }) => account === selectedTradingAccount?.id
			);

			if (selectedAccountDetailedInformation && selectedMarketItem?.code) {
				const matchingRule = marginRules.find((rule) => rule.instruments.includes(selectedMarketItem?.code));
				setDoesRulesApply(!!matchingRule);
				setMarginRequirementsToApply(matchingRule);
			}
		}
	}, [detailedInfo, selectedMarketItem?.code, marginRules]);

	useEffect(() => {
		if (doesRulesApply) {
			if (
				selectedTradingAccount?.accountNumber &&
				selectedMarketItem?.code &&
				current.amountInfo?.amount !== undefined
			) {
				const payload = {
					acctId: selectedTradingAccount?.accountNumber,
					symbol: selectedMarketItem?.code,
					reqId: UniqueId(),
					amount: current.amountInfo?.amount,
					side: marginRulesContext.getPositionSideValue(current.side),
				};

				console.info('rfpGatewayConnection.send(RFP.requestFutureMargin', {
					...payload,
				});
				rfpGateway.send(RFP.requestFutureMargin, payload);
			}
		} else {
			marginRulesContext.resetFutureMargin();
		}
	}, [doesRulesApply, current.amountInfo?.amount, current.side]);

	useEffect(() => {
		console.info('Margin requirements account is use effect, ', { accountId, marginRules });
	}, [accountId, marginRules]);

	useEffect(() => {
		console.info('FUTURE MARGIN use effect, ', { futureMarginReqId, futureMarginValue });
	}, [futureMarginReqId, futureMarginValue]);

	return (
		<MarginRequirementsContext.Provider
			value={{
				selectedAccountNumber: selectedTradingAccount?.accountNumber,
				doesRulesApply,
				marginRequirementsToApply,
			}}
		>
			{children}
		</MarginRequirementsContext.Provider>
	);
};

export default MarginRequirementsContext;
